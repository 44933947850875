import React from "react";
import "./TransitionPopIn.css";
import TransitionSwitchHOC from "./TransitionSwitchHOC";

//based on code by https://stephencookdev.co.uk/
class TransitionSwtichComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classname: this.props.firstClass || "",
      curChild: props.children,
      curUniqId: props.uniqId,
      prevChild: null,
      prevUniqId: null
    };
  }

  timeout = null;

  componentDidUpdate(prevProps, prevState) {
    const prevUniqId = prevProps.transitionKey || prevProps.children.type;
    const uniqId = this.props.transitionKey || this.props.children.type;

    if (prevUniqId !== uniqId) {
      this.setState({
        classname: this.props.outClass || "fadeout-animation",
        curChild: this.props.children,
        curUniqId: uniqId,
        prevChild: prevProps.children,
        prevUniqId
      });
      this.timeout = setTimeout(() => {
        this.swapChildren();
      },375);
    }
  }

  componentDidMount() {
    if(this.timeout)
      clearTimeout(this.timeout);
  }
  

  swapChildren = () => {
    this.setState({
      classname: this.props.inClass || "fadein-animation",
      prevChild: null,
      prevUniqId: null
    });
  };

  render() {
    return (
      <div className={this.state.classname + (this.props.className ? ` ${this.props.className}` : '')}>
        {this.state.prevChild || this.state.curChild}
      </div>
    );
  }
}


export default TransitionSwitchHOC(TransitionSwtichComp);
