import React, { useState } from 'react';
import { HashRouter as Router, Link, Route } from "react-router-dom";
import './App.css';
import TransitionPopInSwitch from './components/Transitions/TransitionPopInSwitchKeyFrames';
import About from "./Routes/About";
import Home from "./Routes/Home";
import Projects from "./Routes/Projects";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuButtonClass = "MenuButton" + (menuOpen ? " Open" : "");
  return (
    <div className="App">
      <Router>
      <div className="NavMain">
        <div className="d-flex align-items-center">
          <div className="NavLogo"><img src="/img/dd-logo.svg"></img></div>
          <div className="NavBrand ml-3">Diamonddrake.com</div>
        </div>
        <div className="NavLinks">
          <Link to="/">Home</Link>
          <Link to="/Projects">Projects</Link>
          <Link to="/About">About</Link>
          <a href="https://github.com/rickeyward">Github</a>
        </div>
        <div
          className={menuButtonClass}
          onClick={() => {
            setMenuOpen(v => !v);
          }}
        >
          <div />
          <div />
          <div />
        </div>
      </div>
      {menuOpen && (
        <div className="NavLinksMini">
          <Link to="/">Home</Link>
          <Link to="/Projects">Projects</Link>
          <Link to="/About">About</Link>
          <a href="https://github.com/rickeyward">Github</a>
        </div>
      )}
      <div className="body">
        <div className="content">
        <TransitionPopInSwitch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </TransitionPopInSwitch>
        </div>
      </div>
      </Router>
    </div>
  );
}

export default App;
