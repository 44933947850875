import React from 'react';

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Home() {
    return (
        <Container>
            <Row>
                <Col md={8}>
                    <Jumbotron className="h-100">
                        <h1>Welcome.</h1>
                        <p>This site has changed a lot over the years. It once hosted hundreds of custom myspace layouts and a flash applet layout generator. A blog of all my endevors learning programming the years before I went to university for it, and well that too is gone. If you came here for that nostaliga, it's lost to the ages.</p>
                        <p>I don't think anyone enjoys reading quite enough to read a blog I'd write. I don't think dev blogs really make that much sense anyway. What we want are tutorials, places to shove code with a little metadata so we can find it easily. Github is a wonderous wealth of information, but its aim is sharing code and its revison. I'd like to store more transient code here. collections of tools and links. I've got a ton of great projects I've been working on and I'll share them here just as soon as I find the time. I hope you find something here useful.</p>
                        <p className="ml-auto">-DiamondDrake</p>
                    </Jumbotron>
                </Col>
                <Col md={4}><iframe className="ghiframe" allowtransparency="true" frameborder="0" scrolling="no" seamless="seamless"
                    src="http://colmdoyle.github.io/gh-activity/gh-activity.html?user=rickeyward&type=user" width="100%" height="100%"></iframe>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;