import React from "react";
import { Route, Switch } from "react-router-dom";

const TransitionSwitchHOC = (AnimatorComponent) => (props) => {
  const { children, inClass, outClass, firstClass, className } = props;
  return (
    <Route
      render={({ location }) => (
        <AnimatorComponent transitionKey={location.pathname} className={className} inClass={inClass} outClass={outClass} firstClass={firstClass}>
          <Switch location={location}>
            {children}
          </Switch>
        </AnimatorComponent>
      )}
    />
  );
};

export default TransitionSwitchHOC;
