import React, { useState } from "react";
import Iframe from "react-iframe";

import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Projects() {
  const [framePopup, setframePopup] = useState(false);

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <h1>Projects.</h1>
            <p>I'm always up to something, seldom documenting it...</p>
          </div>
          <Row>
            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{
                    backgroundImage:
                      "url('https://cdn.instructables.com/FM9/XLGA/IATHJ03J/FM9XLGAIATHJ03J.LARGE.jpg?auto=webp&width=1024&fit=bounds')"
                  }}
                />
                {/* <Card.Img variant="top" src="https://cdn.instructables.com/FM9/XLGA/IATHJ03J/FM9XLGAIATHJ03J.LARGE.jpg?auto=webp&width=1024&fit=bounds" /> */}
                <Card.Body className="d-flex flex-column">
                  <Card.Title>RC Transmitter to USB Gamepad Using Arduino (Instructable Article)</Card.Title>
                  <Card.Text>
                    Great for practicing your RC pilot skills, but you can do this cheaper and easier with a digispark
                    clone. I should probably share that code soon.
                  </Card.Text>
                  <a
                    href="https://www.instructables.com/id/RC-Transmitter-to-USB-Gamepad-Using-Arduino/"
                    target="_blank"
                    className="btn btn-primary btn-block mt-auto">
                    Go to Article
                  </a>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published Jun 13th, 2015</small>
                </Card.Footer>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{ backgroundImage: "url('/img/cursor-flashlight.png')" }}
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Cursor Flashlight (Windows App)</Card.Title>
                  <Card.Text>
                    Darken your screen everywhere except around the cursor when holding left-ctrl + left-win. Useful for explaining things on screen.
                  </Card.Text>
                  <div className="d-flex mt-auto cardbuttons2 justify-content-between">
                  <a
                      href="https://github.com/RickeyWard/CursorFlashlight/releases/download/0.1a/CursorFlashlight.exe"
                      target="_blank"
                      className="btn btn-primary d-block mr-10">
                      Download
                    </a>
                    <a
                      href="https://github.com/RickeyWard/CursorFlashlight"
                      target="_blank"
                      className="btn btn-primary d-block">
                      Code
                    </a>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published Feb 22, 2020</small>
                </Card.Footer>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{ backgroundImage: "url('/img/bullet-snake.png')" }}
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Bullet Snake! (Silly game in p5js)</Card.Title>
                  <Card.Text>
                    This was made to just play around with the p5js web editor, ended up being a ton of fun!
                  </Card.Text>
                  <div className="d-flex mt-auto cardbuttons2 justify-content-between">
                    <Button
                      variant="primary"
                      className="d-block mr-10"
                      onClick={() =>
                        setframePopup({
                          title: "Bullet Snake!",
                          url: "https://editor.p5js.org/diamondrake/present/onJcf4UJE",
                          width: "400px",
                          height: "400px"
                        })
                      }>
                      Play
                    </Button>
                    <a
                      href="https://editor.p5js.org/diamondrake/sketches/onJcf4UJE"
                      target="_blank"
                      className="btn btn-primary d-block">
                      Code
                    </a>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published Jul 6, 2019</small>
                </Card.Footer>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{ backgroundImage: "url('/img/minesweeper.png')" }}
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>Worst Minesweeper? (first tinker with p5js)</Card.Title>
                  <Card.Text>
                    I had minesweeper on the mind, this is the worst version ever made. No flagging, no first click
                    detection. Just messy minsweeping. One day I'll revisit it. First time I ever used p5js
                  </Card.Text>
                  <div className="d-flex mt-auto cardbuttons2 justify-content-between">
                    <Button
                      variant="primary"
                      className="d-block mr-10"
                      onClick={() =>
                        setframePopup({
                          title: "Worst Minesweeper?",
                          url: "https://editor.p5js.org/diamondrake/present/r19kqNo3X",
                          width: "400px",
                          height: "400px"
                        })
                      }>
                      Play
                    </Button>
                    <a
                      href="https://editor.p5js.org/diamondrake/sketches/r19kqNo3X"
                      target="_blank"
                      className="btn btn-primary d-block">
                      Code
                    </a>
                  </div>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published Nov 3, 2018</small>
                </Card.Footer>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{ backgroundImage: "url('/img/css-skyline.png')" }}
                />
                {/* <Card.Img variant="top" src="/img/pl-git.png" /> */}
                <Card.Body className="d-flex flex-column">
                  <Card.Title>CSS Skyline</Card.Title>
                  <Card.Text>
                    Experimenting with drawing in pure CSS, First time giving it a shot.
                  </Card.Text>
                  <a href="https://codepen.io/diamonddrake/full/dyoORxv" target="_blank" className="btn btn-primary btn-block mt-auto">
                    Go to Codepen
                  </a>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published Feb 22, 2020</small>
                </Card.Footer>
              </Card>
            </Col>

            <Col sm={12} md={6} lg={4} className="mt-4">
              <Card className="h-100">
                <div
                  className="project-card-image card-img-top"
                  style={{ backgroundImage: "url('/img/pl-git.png')" }}
                />
                {/* <Card.Img variant="top" src="/img/pl-git.png" /> */}
                <Card.Body className="d-flex flex-column">
                  <Card.Title>More to come</Card.Title>
                  <Card.Text>
                    I never seem to find time to post my projects here, so if you want to see more I have a few public
                    repos and gists on Github.
                  </Card.Text>
                  <a href="https://github.com/rickeyward" target="_blank" className="btn btn-primary btn-block mt-auto">
                    Check out my Github
                  </a>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Updated daily, practically.</small>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <IframeModal show={!!framePopup} onHide={() => setframePopup(null)} {...framePopup} />
    </Container>
  );
}

export default Projects;

function IframeModal(props) {
  return (
    <Modal
      {...{ show: props.show, onHide: props.onHide }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title && props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-content-center">
        <Iframe
          url={props.url && props.url}
          width={props.width && props.width}
          height={props.height && props.height}
          id="reactSnake"
          className="no-border"
          display="initial"
          position="relative"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          I hate games
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
