import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

function About() {
  return (
    <Container>
      <Row>
        <Col sm={12} md={4}>
            <h1>About.</h1>
            <p>A dedicated developer and passionate learner. I write software, design, create and build embeded systems, web interfaces, custom circuit boards, model aircraft, voice applications, mobile apps, and more.</p>
        </Col>
        <Col sm={12} md={4}>
            <div className="text-right">
                <h3>DiamondDrake</h3>
                <p className="text-muted">Rickey Ward</p>
            </div>
        </Col>
        <Col sm={12} md={4}>
        <Card className="h-100">
                <Card.Img className="flipx" variant="top" src="/img/about.jpg" />
                <Card.Footer className="text-right">
                  <small className="text-muted">Rickey Ward 2017</small>
                </Card.Footer>
              </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
